import { LOGIN_DATA, LOGIN_LOAD, LOGIN_ERROR } from '../actionTypes';

const INITIAL_STATE = {
  data: {
    token: null,
  },
  load: false,
  error: null,
};

export const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case LOGIN_LOAD: {
      return {
        ...state,
        load: action.load,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return state;
  }
};