const LOGIN_DATA = 'LOGIN_DATA';
const LOGIN_LOAD = 'LOGIN_LOAD';
const LOGIN_ERROR = 'LOGIN_ERROR';

const GET_NEWS_DATA = 'GET_NEWS_DATA';
const GET_NEWS_LOAD = 'GET_NEWS_LOAD';
const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

const GET_PARTNER_DATA = 'GET_PARTNER_DATA';
const GET_PARTNER_LOAD = 'GET_PARTNER_LOAD';
const GET_PARTNER_ERROR = 'GET_PARTNER_ERROR';

const GET_VACANCIES_DATA = 'GET_VACANCIES_DATA';
const GET_VACANCIES_LOAD = 'GET_VACANCIES_LOAD';
const GET_VACANCIES_ERROR = 'GET_VACANCIES_ERROR';

export {
  LOGIN_DATA,
  LOGIN_LOAD,
  LOGIN_ERROR,
  GET_NEWS_DATA,
  GET_NEWS_LOAD,
  GET_NEWS_ERROR,
  GET_PARTNER_DATA,
  GET_PARTNER_ERROR,
  GET_PARTNER_LOAD,
  GET_VACANCIES_DATA,
  GET_VACANCIES_ERROR,
  GET_VACANCIES_LOAD
}
