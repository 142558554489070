import {GET_VACANCIES_DATA, GET_VACANCIES_ERROR, GET_VACANCIES_LOAD} from '../actionTypes';

const INITIAL_STATE = {
  data: {
    data: []
  },
  load: false,
  error: null,
};

export const getVacanciesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VACANCIES_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case GET_VACANCIES_LOAD: {
      return {
        ...state,
        load: action.load,
      };
    }
    case GET_VACANCIES_ERROR: {
      return {
        ...state,
        error: action.error,
      }
    }
    default:
      return state;
  }
}
