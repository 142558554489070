import {combineReducers} from 'redux';
import {loginReducer} from './reducers/loginReducer';
import {getNewsReducer} from './reducers/getNewsReducer';
import {getPartnersReducer} from "./reducers/getPartnersReducer";
import {getVacanciesReducer} from "./reducers/getVacanciesReducer";

const rootReducer = combineReducers({
  loginState: loginReducer,
  getNewsState: getNewsReducer,
  getPartnersState: getPartnersReducer,
  getVacanciesState: getVacanciesReducer,
});

export {rootReducer};
